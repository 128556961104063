import styled from 'styled-components'
import { Statistic, Select } from 'antd';

export const PageContainer = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: center;
 margin-top: 0.5em;

 ${(props) => props.windowDimensions.width <= 640 && 'overflow-x: hidden;'}
`

export const FilterContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`

export const AntdSelect = styled(Select)`
     ${(props) =>
    props.windowDimensions.width >= 1000 ? 'width: 500px;' : 'width: 159px;'}
`

export const TotalsContainer = styled.div`
 display: flex;
 ${(props) =>
    props.windowDimensions.width >= 1000 ? `
     flex-direction: row;` : `

     flex-direction: column;
     align-items: center;
     margin-bottom: 3em;
  `   
}

 justify-content: space-evenly;
 width: 50em;
 margin-top: 2em;
`

export const TotalItem = styled(Statistic)`
    ${(props) =>
    props.windowDimensions.width >= 1000 ? 'border-right: 1px solid black;' : `
        border-bottom: 1px solid black;
        text-align: center;
    `}
    padding: 2em; 
    width: 250;

`

export const ChartsContainer = styled.div` 
  
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
    props.windowDimensions.width >= 1000 ? `
        flex-direction: row;
        margin-bottom: 1.5em;
        margin-top: 2em;
    
    ` : `
        flex-direction: column;
      
    `}   
`

export const Chart = styled.div` 
 ${(props) =>
    props.windowDimensions.width >= 1000 ? `
      
    ` : `
        margin-bottom: 5em;
    `}
`

export const ChartTitle = styled.div` 
    font-weight: bolder;
    font-size: 16px;
    text-align: center;
    margin-bottom: 1em;
`