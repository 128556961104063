import { api, getHeaders } from '../index';

export const getCardsTransactions = async (params) =>{
  const response = await api.post(`/transactions/cards`, params, {
    headers: getHeaders(),
  });
  return {
    transactionData: response.data,
    status: !!response.data
  }   
}

export const getCashTransactions = async (params) =>{
  const response = await api.post(`/transactions/cash`, params,{
    headers: getHeaders(),
  });
  return {
    transactionData: response.data,
    status: !!response.data
  }   
}

export const getPixTransactions = async (params) =>{
  const response = await api.post(`/transactions/pix`, params, {
    headers: getHeaders(),
  });

  return {
    transactionData: response.data,
    status: !!response.data
  }
}
