
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
     /* * {
      box-sizing: border-box;
       margin: 0;
       padding: 0;
    }
    
    body {  
        background-color: #FAFAFA;
        font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
      }
      
      @media (max-width: 1920px){
  .container {
    width: 1230px;
  }
}

@media (max-width: 992px){
  .container {
    width: 960px;
    max-width: 100%;
  }
}

.container {
   position: relative;
   margin-left: auto;
   margin-right: auto;
}


    .page-loader{
       position: absolute;
    } */
`;