import React, { useEffect, useState, createContext } from 'react'

export const WindowDimensionsContext = createContext({})

const WindowDimensionsProvider = ({ children }) => {
  const handleWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(handleWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(handleWindowDimensions())
    }

    setTimeout(() => {
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, 1000)
  }, [])

  return (
    <WindowDimensionsContext.Provider value={windowDimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  )
}

export default WindowDimensionsProvider
