import React, {useState} from 'react';
import { Form, Input, Button, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { login } from '../providers/api/auth/auth';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const FormContainer = styled.div`
    background-color: white;
    padding: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
`

export const Header = styled.header`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 2em;
    font-size: 16pt;

    img{
        height: 50px;
    }
    
`

const Login = () =>{

    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const spinIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const [requestLoading, setRequestLoading] = useState(false);

    const handleOnFinish = async (values) =>{
        setRequestLoading(true)
         const response = await login(values);
         setRequestLoading(false);
         if(!response.status){
            messageApi.open({
                type: 'error',
                content: 'Credenciais Invalidas.',
              });
         }

         navigate('/')
    }


    return(
        <FormContainer>
             <Spin className="page-loader" indicator={spinIcon} spinning={requestLoading}>
                {contextHolder}
                <Header>
                    {/* TODO: Criar contexto para pegar a logo do canal e o tema */}
                    {/* <img alt="companyLogo" src={require('../../assets/img/logo.png')}></img> */}
                    <div>Dashboard</div>
                </Header>
                <Form
                useRef
                name="lgin-form"
                style={{ width: 470 }}
                onFinish={handleOnFinish}
                >
                            <Form.Item
                    label="Login"
                    name="login"
                    rules={[{ required: true, message: 'Informe o Login!' }]}
                    >
                    <Input />
                    </Form.Item>

                    <Form.Item
                    label="Senha"
                    name="password"
                    rules={[{ required: true, message: 'Informe a senha!' }]}
                    >
                        <Input.Password />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                    </Form.Item>
                </Form>
             </Spin>

        </FormContainer>
    )
}

export default Login; 